export default [
  {
    header: 'Verloningen',
    icon: 'DollarSignIcon',
    action: 'read',
    resource: 'payslips',
    children: [
      {
        title: 'Loonstroken',
        route: 'app-payslips',
        icon: 'FileTextIcon',
        action: 'read',
        resource: 'payslips',
      },
    ],
  },
]
