export default [
  {
    header: 'Gebruikers',
    icon: 'UserIcon',
    action: 'read',
    resource: 'users',
    children: [
      {
        title: 'Bedrijven',
        route: 'app-companies',
        icon: 'BriefcaseIcon',
        action: 'read',
        resource: 'company',
      },
      {
        title: 'Freelancers',
        route: 'app-freelancer',
        icon: 'UsersIcon',
        action: 'read',
        resource: 'freelancer',
      },
      {
        title: 'Gebruikers',
        route: 'app-users-list',
        icon: 'UserIcon',
        action: 'read',
        resource: 'account',
      },
    ],
  },
]
