export default [
  {
    header: 'Administratie',
    icon: 'BookIcon',
    action: 'read',
    resource: 'invoice',
    children: [
      {
        title: 'Facturen',
        route: 'app-invoices',
        icon: 'FileIcon',
        action: 'read',
        resource: 'invoice',
      },
      {
        title: 'Verzamelfacturen',
        route: 'app-invoice-collection',
        icon: 'FileTextIcon',
        action: 'read',
        resource: 'invoice-collection',
      },
    ],
  },
]
